import React from 'react'
import PropTypes from 'prop-types'
import CardWrapper from '../CardWrapper/dynamic'
import SkeletonAnimation from '../SkeletonAnimation/dynamic'
import ProgressiveImage from '../ProgressiveImage/dynamic'
import StreamingScreenshotStyled from './styled'

import { withTranslation } from '../../../i18n'

import CarSrcImg from '../../assets/images/car.png'
import MemberIcon from '../../assets/images/detection-snapshot/member-icon.svg'

export class StreamingScreenshotWrapper extends React.PureComponent {
  state = {
    coordinates: [],
    containerWidth: 0,
    containerHeight: 0,
    isLoadingImageAnnotation: false,
  }

  annotationContainer = undefined

  applicationStage = undefined

  containerImage = undefined

  imageSprite = undefined

  containerShape = undefined

  screenShotWidth = undefined

  screenshotHeight = undefined

  PIXI = undefined

  ratio = 1

  async componentDidMount() {
    // eslint-disable-next-line global-require
    this.PIXI = require('pixi.js')
    if (this.props.carData && !this.props.loading) {
      await this.preparePoints()
      await this.createImageAndAnnotation()
    }
    window.addEventListener('resize', this.handleAnnotationSizeChanged)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleAnnotationSizeChanged)
  }

  async componentDidUpdate(prevProps) {
    // eslint-disable-next-line global-require
    const isFinishLoading = prevProps.loading && !this.props.loading
    const noDataToData = !prevProps.carData && this.props.carData
    const dataToNewData = prevProps.carData && this.props.carData && prevProps.carData.id !== this.props.carData.id
    const dataValid = !!this.props.carData
    const isSocket = !prevProps.loading && !this.props.loading
    if ((isFinishLoading && dataValid) || (isSocket && noDataToData)) {
      await this.preparePoints()
      await this.createImageAndAnnotation()
    }
    if (isSocket && dataToNewData) {
      this.containerImage.removeChildren()
      this.containerShape.removeChildren()
      await this.preparePoints()
      await this.updateImage()
      this.generateBoundingBox()
    }
  }

  async createImageAndAnnotation() {
    this.createApplicationStage()
    this.createImageContainer()
    await this.updateImage()
    this.createShapeContainer()
    this.generateBoundingBox()
  }

  async preparePoints() {
    const pointString = this.props.carData.bbox || ''
    const tempPoints = pointString.split(',')
    let coordinates = tempPoints.reduce((prev, current, index) => {
      const point = current * 1
      if (index % 2 === 0) {
        const data = {
          x: point,
        }
        return [...prev, data]
      }

      const lastElement = prev[prev.length - 1]
      const updatedLastElement = { ...lastElement, y: point }
      return [...prev.slice(0, prev.length - 1), updatedLastElement]
    }, [])
    coordinates = [...coordinates, coordinates[0]]
    await this.setState({
      coordinates: coordinates.map(coordinateData => new this.PIXI.Point(coordinateData.x, coordinateData.y)),
    })
  }

  createApplicationStage() {
    this.annotationContainer = document.getElementById('annotation-license-plate')
    this.applicationStage = new this.PIXI.Application({
      id: 'canvas-annotation',
      width: this.annotationLicensePlate.offsetWidth,
      height: this.annotationLicensePlate.offsetHeight,
      autoResize: true,
      backgroundColor: 0x1e1e1e,
      resolution: 1,
      antialias: true,
      resizeTo: this.annotationLicensePlate,
    })
    this.annotationContainer.appendChild(this.applicationStage.view)
  }

  createImageContainer() {
    this.containerImage = new this.PIXI.Container()
    this.applicationStage.stage.addChild(this.containerImage)
  }

  setupTexture(texture) {
    this.setState({
      isLoadingImageAnnotation: false,
    })
    this.screenshotWidth = texture.width
    this.screenshotHeight = texture.height
    this.ratio = Math.min(
      this.annotationLicensePlate.offsetWidth / this.screenshotWidth,
      this.annotationLicensePlate.offsetHeight / this.screenshotHeight,
    )
    this.imageSprite = new this.PIXI.Sprite(texture)
    this.imageSprite.width = this.screenshotWidth
    this.imageSprite.height = this.screenshotHeight
    this.imageSprite.scale.x = this.ratio
    this.imageSprite.scale.y = this.ratio

    const translateX = (this.annotationLicensePlate.offsetWidth - this.imageSprite.width) / 2
    const translateY = (this.annotationLicensePlate.offsetHeight - this.imageSprite.height) / 2
    this.imageSprite.x = translateX
    this.imageSprite.y = translateY
    this.containerImage.addChild(this.imageSprite)
  }

  imageUpdateSize() {
    const prom = new Promise(resolve => {
      const texture = this.PIXI.Texture.from(this.props.carData.imgSrc)
      this.setState({
        isLoadingImageAnnotation: true,
      })
      if (texture.valid) {
        this.setupTexture(texture)
        resolve()
      }
    })
    return prom
  }

  updateImage() {
    const prom = new Promise(resolve => {
      const texture = this.PIXI.Texture.from(this.props.carData.imgSrc)
      this.setState({
        isLoadingImageAnnotation: true,
      })
      texture.on('update', () => {
        this.setupTexture(texture)
        resolve()
      })
    })
    return prom
  }

  createShapeContainer() {
    this.containerShape = new this.PIXI.Container()
    this.containerShape.interactive = true
    this.applicationStage.stage.addChild(this.containerShape)
  }

  generateBoundingBox() {
    const { coordinates } = this.state
    const rectCoordinates = coordinates.map(point => {
      const translateX = (this.annotationLicensePlate.offsetWidth - this.imageSprite.width) / 2
      const translateY = (this.annotationLicensePlate.offsetHeight - this.imageSprite.height) / 2
      const x = point.x * this.ratio + translateX
      const y = point.y * this.ratio + translateY
      return new this.PIXI.Point(x, y)
    })
    const lineArray = rectCoordinates.reduce((prev, current, index) => {
      if (index === rectCoordinates.length - 1) {
        return prev
      }
      const lineGraphic = new this.PIXI.Graphics()
      lineGraphic.lineStyle(2, 0x981214, 1)
      const nextPoint = rectCoordinates[index + 1]
      lineGraphic.moveTo(current.x, current.y, nextPoint.x, nextPoint.y)
      lineGraphic.lineTo(nextPoint.x, nextPoint.y)
      return [...prev, lineGraphic]
    }, [])
    this.containerShape.removeChildren()
    lineArray.forEach(line => this.containerShape.addChild(line))
  }

  handleAnnotationSizeChanged = async () => {
    if (this.applicationStage && this.containerImage) {
      this.applicationStage.resize()
      this.containerImage.removeChildren()
      await this.imageUpdateSize()
      this.generateBoundingBox()
    }
  }

  renderMemberLogo() {
    let output = null
    const { isMember } = this.props.carData
    if (isMember) {
      output = (
        <div className="member-logo">
          <img src={MemberIcon} />
        </div>
      )
    }
    return output
  }

  renderCompanyName() {
    let output = null
    const { isMember, destination } = this.props.carData
    if (isMember && destination) {
      output = (
        <div className="overlay-company-name">
          <div className="company-name">{destination.company.nameEn}</div>
        </div>
      )
    }
    return output
  }

  renderImageAndOverlay() {
    const { carData } = this.props
    let content = null
    let className = ''
    if (this.props.loading) {
      className = 'no-data-wrapper flex justify-center align-items-center'
      content = <SkeletonAnimation id="streaming-skeleton-loading" className="streaming-skeleton" />
    } else if (!this.props.carData) {
      const noData = this.props.t('no_data')
      className = 'no-data-wrapper flex justify-center align-items-center'
      content = noData
    } else {
      className = 'image-overlay-wrapper'
      const progressiveImage = (
        <ProgressiveImage
          id="screenshot-progressive-img"
          className="screenshot-progressive-img"
          placeHolderImgSrc={this.props.carData.pixelatedSrc}
          src={this.props.carData.pixelatedSrc}
        />
      )
      const vehicleTypeText = this.props.t('vehicle_type')
      const licensePlateText = this.props.t('license_plate')
      const plateOrigin = this.props.t('province')
      const plateColor = this.props.t('plate_color')
      const plateOriginValue = carData.plateOrigin === 'others' ? this.props.t(carData.plateOrigin) : carData.plateOrigin
      content = (
        <div id="annotation-license-plate">
          {this.state.isLoadingImageAnnotation ? progressiveImage : null}
          {this.renderCompanyName()}
          {this.renderMemberLogo()}
          <div className="overlay-wrapper flex">
            <div className="overlay-detail-item" data-test="lbl-screenshot-detail-vehicle-type">
              <div className="overlay-detail-label">{vehicleTypeText}</div>
              <div className="overlay-detail-value">
                <div>{this.props.t(carData.vehicleType)}</div>
              </div>
            </div>
            <div className="overlay-detail-item" data-test="lbl-screenshot-detail-license-plate">
              <div className="overlay-detail-label">{licensePlateText}</div>
              <div className="overlay-detail-value">
                <div>{carData.licensePlate}</div>
              </div>
            </div>
            <div className="overlay-detail-item" data-test="lbl-screenshot-detail-license-plate">
              <div className="overlay-detail-label">{plateColor}</div>
              <div className="overlay-detail-value">
                <div>{this.props.t(carData.plateColor)}</div>
              </div>
            </div>
            <div className="overlay-detail-item" data-test="lbl-screenshot-detail-license-plate">
              <div className="overlay-detail-label">{plateOrigin}</div>
              <div className="overlay-detail-value">
                <div>{plateOriginValue}</div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div
        ref={ref => {
          this.annotationLicensePlate = ref
        }}
        className={className}
      >
        {content}
      </div>
    )
  }

  renderContent() {
    const title = this.props.t('gate_monitor')
    return (
      <CardWrapper title={this.props.carData?.cameraName || title} id="header-screenshot">
        {this.renderImageAndOverlay()}
      </CardWrapper>
    )
  }

  checkMessage(message, val) {
    return message === '' ? val : message
  }

  render() {
    return <StreamingScreenshotStyled className={this.props.className}>{this.renderContent()}</StreamingScreenshotStyled>
  }
}

StreamingScreenshotWrapper.defaultProps = {
  imgSrc: CarSrcImg,
  loading: false,
}

StreamingScreenshotWrapper.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  carData: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    cameraName: PropTypes.string,
    imgSrc: PropTypes.string,
    pixelatedSrc: PropTypes.string,
    vehicleType: PropTypes.string,
    licensePlate: PropTypes.string,
    plateColor: PropTypes.string,
    destination: PropTypes.shape({
      company: PropTypes.shape({
        nameEn: PropTypes.string,
        nameTh: PropTypes.string,
      }),
    }),
    plateOrigin: PropTypes.string,
    bbox: PropTypes.string,
    isMember: PropTypes.bool,
  }),
  loading: PropTypes.bool,
}

export default withTranslation()(StreamingScreenshotWrapper)
