import styled from 'styled-components'
import device from '../../utils/device-size'

export default styled.div`
  #annotation-license-plate {
    width: 100%;
    height: 100%;
    .member-logo {
      position: absolute;
      width: 112px;
      height: 42px;
      right: 8px;
      top: 10px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        box-shadow: none;
      }
    }
    .overlay-company-name {
      position: absolute;
      max-width: calc(100% - 145px);
      width: auto;
      height: 42px;
      left: 8px;
      top: 10px;
      display: flex;
      align-items: center;
      padding: 8px;
      background-image: linear-gradient(to right, #070707b5, #000000b8);
      .company-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        font-family: 'Prompt';
        font-weight: 500;
      }
    }
  }
  .no-data-wrapper {
    width: 728px;
    height: 409px;
    font-family: 'Prompt-Medium';
    font-size: 18px;
    font-weight: 500;
  }

  .image-overlay-wrapper {
    position: relative;
    width: 728px;
    height: 409px;
    canvas {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    }
    .overlay-wrapper {
      position: absolute;
      bottom: 14px;
      left: 14px;
      background-image: linear-gradient(to right, #981214b5, #c9292db8);
      color: #fff;
      font-size: 12px;
      font-family: 'Prompt';
      white-space: nowrap;
      padding: 8px 32px;
      width: calc(100% - 28px);
      display: flex;
      .overlay-detail-item {
        padding-right: 32px;
        width: 25%;
        .overlay-detail-label {
          font-size: 12px;
          width: fit-content;
        }
        .overlay-detail-value {
          font-size: 16px;
          font-weight: 500;
          width: 100%;
          overflow: hidden;
          div {
            overflow: hidden;
            width: fit-content;
            min-width: 100%;
            animation: slider 5s infinite;
          }
        }
        @keyframes slider {
          0% {
            transform: translateX(0);
          }

          50% {
            transform: translateX(calc(-100% + 127px));
          }

          100% {
            transform: translateX(0);
          }
        }
      }
    }
    .screenshot-progressive-img {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  @media only screen and (max-width: ${device.sizeL}) {
    .image-overlay-wrapper {
      .overlay-wrapper {
        padding: 13px 24px;
      }
    }
    @keyframes slider {
      0% {
        transform: translateX(0);
      }

      50% {
        transform: translateX(calc(-100% + 78.75px));
      }

      100% {
        transform: translateX(0);
      }
    }
  }
  @media only screen and (max-width: ${device.sizeM}) {
    .image-overlay-wrapper {
      .overlay-wrapper {
        padding: 8px 32px;
      }
    }
    @keyframes slider {
      0% {
        transform: translateX(0);
      }

      50% {
        transform: translateX(calc(-100% + 95px));
      }

      100% {
        transform: translateX(0);
      }
    }
  }
  @media only screen and (max-width: ${device.sizeS}) {
    @keyframes slider {
      0% {
        transform: translateX(0);
      }

      50% {
        transform: translateX(calc(-100% + calc(calc(calc(100vw - 124px) * 0.25) - 34px)));
      }

      100% {
        transform: translateX(0);
      }
    }
  }
`
